import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import styled from '@mui/system/styled';
import usePipelineLogs from 'hooks/pipelines/use-pipeline-logs';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '50%',
    minWidth: '600px'
  }
}));

const LogsContainer = styled(Box)(({ theme }) => ({
  padding: '16px',
  height: 'calc(100vh - 180px)',
  backgroundColor: '#f5f5f5',
  overflowY: 'auto',
  fontFamily: 'monospace',
  fontSize: '14px',
  whiteSpace: 'pre-wrap'
}));

const LogsView = ({ open, onClose, pipelineId }) => {
  const { t } = useTranslation();
  const [selectedPod, setSelectedPod] = useState('');
  const [shouldFetch, setShouldFetch] = useState(false);

  const {
    data: logs,
    isLoading,
    isError,
    error
  } = usePipelineLogs(pipelineId, shouldFetch);

  React.useEffect(() => {
    if (open) {
      setShouldFetch(true);
    } else {
      setShouldFetch(false);
      setSelectedPod('');
    }
  }, [open]);

  const podNames = logs ? Object.keys(logs) : [];

  React.useEffect(() => {
    if (logs && podNames.length > 0 && !selectedPod) {
      setSelectedPod(podNames[0]);
    }
  }, [logs]);

  const handleClose = () => {
    setShouldFetch(false);
    setSelectedPod('');
    onClose();
  };

  return (
    <StyledDrawer
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <Box sx={{ p: 2, borderBottom: '1px solid #E9E9E9' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">
              {t('feature.configure.pipelines.logs.title')} - {pipelineId}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 2, borderBottom: '1px solid #E9E9E9' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {t('feature.configure.pipelines.logs.select_pod')}:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              fullWidth
              value={selectedPod}
              onChange={(e) => setSelectedPod(e.target.value)}
              size="small"
              disabled={isLoading || podNames.length === 0}
            >
              {podNames.map((podName) => (
                <MenuItem key={podName} value={podName}>
                  {podName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>

      <LogsContainer>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Typography color="error" sx={{ p: 2 }}>
            {error?.message || t('feature.configure.pipelines.logs.error_message')}
          </Typography>
        ) : selectedPod && logs ? (
          <Box sx={{
            fontFamily: 'monospace',
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
            p: 2
          }}>
            {logs[selectedPod]}
          </Box>
        ) : (
          <Typography variant="body2" color="textSecondary" align="center" sx={{ pt: 2 }}>
            {t('feature.configure.pipelines.logs.select_pod_message')}
          </Typography>
        )}
      </LogsContainer>
    </StyledDrawer>
  );
};

export default LogsView;
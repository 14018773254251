import React, {useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CategoryIcon from '@mui/icons-material/Category';
import {format, parseISO} from 'date-fns';
import {useSearchParams} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import useHover from 'hooks/useHover';
import CardHeaderColumn from 'components/elements/CardHeader';
import CardHeaderIcon from 'components/elements/CardHeaderIcon';
import PersonIcon from "@mui/icons-material/Person";
import {AvatarSmall} from "../../../../components/icons/Avatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogsView from './logs-viewer';
import Authorized from '../../../../components/base/Authorized';

const CardRoot = styled(Box)(() => ({
    padding: '2px 8px 6px 0px',
}));

const CardStatus = styled(Box)(({color, expanded = false}) => ({
    backgroundColor: color,
    width: '0.5em',
    height: 'auto',
    marginTop: '-0.75em',
    marginBottom: '-0.75em',
    borderBottomLeftRadius: expanded ? 0 : '4px',
    borderTopLeftRadius: '4px'
}));

const Accordion = styled(MuiAccordion)(({theme, active = false}) => ({
    border: '0.1em solid #E9E9E9',
    boxShadow: 'none',
    ...(active && {
        background: 'aliceblue',
    }),
}));

const PipelineCard = ({style, data, index, scroll}) => {
    const {t} = useTranslation();
    const [hoverRef] = useHover();
    let [searchParams] = useSearchParams();
    const [showLogs, setShowLogs] = useState(false);

  const [expand, setExpand] = useState(false);

    const toggleAccordion = (status) => {
        if (status === 'ERROR') {
            setExpand((prev) => !prev);
        }
    };

    const isActive = () => {
        return data['run_id'] === searchParams.get('pipeline_id')
    }

    const getType = (type) => {
        if (type === 'Impact Analysis') {
            return t('feature.configure.pipelines.impact_lever_analysis');
        } else if (type === 'Trends Analysis') {
            return t('feature.configure.pipelines.trend_analysis');
        } else if (type === 'PPSM') {
            return t('feature.configure.pipelines.ppsm');
        } else {
            return type;
        }
    }

    const statusTooltip = (status) => {
        switch (status) {
            case "SUCCESS":
                return t('feature.configure.pipelines.status.success');
            case "ERROR":
                return t('feature.configure.pipelines.status.error');
            case "RUNNING":
                return t('feature.configure.pipelines.status.running');
            default:
                return '';
        }
    }

    const statusIcon = (status) => {
        switch (status) {
            case 'SUCCESS':
                return <CheckCircleIcon sx={{color: '#00c360'}}/>;
            case 'ERROR':
                return <CancelIcon sx={{color: '#ff0000'}}/>;
            case 'RUNNING':
                return <PendingIcon sx={{color:'#EED437'}}/>;
            default:
                return <></>;
        }
    };

    const executedUser = (executed_user) =>{
        if(executed_user.first_name || executed_user.last_name){
            return `${executed_user.first_name} ${executed_user.last_name}`
        }else{
            return ''
        }
    }

    return (
        <CardRoot style={style} ref={hoverRef}>
            <Accordion expanded={expand} TransitionProps={{unmountOnExit: true}} active={isActive()}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&.MuiAccordionSummary-root': {boxShadow: '1px 2px 13px -10px #888'},
                        '&.MuiAccordionSummary-root.Mui-expanded': {minHeight: 'unset'},
                        div: {
                            '&.MuiAccordionSummary-content.Mui-expanded': {margin: '12px 0'},
                        },
                        '&:hover': {
                            cursor: data.status === 'ERROR' ? 'pointer' : 'default !important'
                        }
                    }}

                >
                    <Grid container alignItems="center" justifyContent="space-between" flexWrap='nowrap' spacing={1}
                          onClick={() => toggleAccordion(data['status'])}>
                            <Grid item container xs={0.4} alignItems='center' justifyContent='center'>
                                <Tooltip title={statusTooltip(data.status)}>{statusIcon(data.status)}</Tooltip></Grid>
                            <Grid item xs={0.2} sx={{height: '80%'}}>
                                <Divider sx={{'&.MuiDivider-root': {borderWidth: '1px', borderColor: '#a7a7a7',width: 'fit-content'}}}
                                         orientation='vertical'/>
                            </Grid>
                            <CardHeaderColumn
                                spacing={3}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <DirectionsRunOutlinedIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.configure.pipelines.run_id')}
                                headerTitle={<Grid
                                    item
                                    container
                                    alignItems="center"
                                >
                                    <Grid item>{data['run_id']}</Grid>
                                </Grid>}
                            />
                            <CardHeaderColumn
                                spacing={2.4}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <CategoryIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.configure.pipelines.type')}
                                headerTitle={getType(data['type'])}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <AccessTimeFilledIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.configure.pipelines.start_time')}
                                headerTitle={format(parseISO(data['start_timestamp']), 'Pp')}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <AccessTimeFilledIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.configure.pipelines.end_time')}
                                headerTitle={data.status === 'RUNNING' ? '-' : format(parseISO(data['end_timestamp']), 'Pp')}
                            />
                            <CardHeaderColumn
                                spacing={2}
                                headerIcon={
                                    <CardHeaderIcon>
                                        <PersonIcon/>
                                    </CardHeaderIcon>
                                }
                                headerName={t('feature.configure.pipelines.executed_by')}
                                headerTitle={<Grid container alignItems={"center"} spacing={1} wrap={"nowrap"}>
                                    {executedUser(data.executed_user) !=='' &&<AvatarSmall userName={executedUser(data.executed_user)}/>}
                                    <Grid item>{executedUser(data.executed_user)?executedUser(data.executed_user):'-'}</Grid></Grid>}
                            />
                          <Authorized action={'pipelines.viewLogs'}>
                            <Grid item container xs={0.5} alignItems='center' justifyContent='center'>
                              {data.status === 'ERROR' && (
                                <Tooltip title={t('feature.configure.pipelines.view_logs')}>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setShowLogs(true);
                                    }}
                                  >
                                    <InfoOutlinedIcon sx={{ color: 'rgba(85,163,218,0.8)' }} fontSize="medium" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid>
                          </Authorized>



                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container pt={2} spacing={2}>
                        <Grid item xs={12}>
                            <Alert icon={false}
                                   severity="error" action={
                                data['trace_id'] !== '' && <CopyToClipboard text={data['trace_id']}>
                                    <Tooltip title={t('Copy Trace ID')}>
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                        >
                                            <ContentCopyIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                            }>{data['display_error'] ? <Box>
                                    <Typography fontSize={'0.875rem'}>Dataset upload failed due to below
                                        reasons</Typography>
                                    <ul>
                                        {data['display_error'].map(error => {
                                            return <li>{error}</li>
                                        })
                                        }
                                    </ul>
                                    <Typography fontSize={'0.875rem'}>If this continues please contact support with trace
                                        id.</Typography>
                                </Box> :
                                <Box><Typography fontSize={'0.875rem'}>{t('feature.configure.pipelines.error')}. If this
                                    continues please contact support with trace id.</Typography></Box>}
                            </Alert>

                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
          <LogsView
            open={showLogs}
            onClose={() => setShowLogs(false)}
            pipelineId={data['run_id']}
          />
        </CardRoot>
    );
};

export default PipelineCard;
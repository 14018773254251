import {Trans} from 'react-i18next';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FolderIcon from '@mui/icons-material/Folder';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CompareIcon from '@mui/icons-material/Compare';

// base routes
export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/auth/signin';
export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/reset-password';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';

// investigate routes
export const ROUTE_INVESTIGATE = '/investigate';
export const ROUTE_INVESTIGATE_TRENDS = `${ROUTE_INVESTIGATE}/trends`;
export const ROUTE_INVESTIGATE_OPPORTUNITIES = `${ROUTE_INVESTIGATE}/opportunities`;

// take action routes
export const ROUTE_TAKE_ACTION = '/take-action';
export const ROUTE_TAKE_ACTION_SAVED = `${ROUTE_TAKE_ACTION}/saved`;
export const ROUTE_TAKE_ACTION_CUSTOM = `${ROUTE_TAKE_ACTION}/custom`;
export const ROUTE_TAKE_ACTION_NU_PLAYS = `${ROUTE_TAKE_ACTION}/plays`;
export const ROUTE_TAKE_ACTION_NU_PLAYS_V2 = `${ROUTE_TAKE_ACTION}/plays-v2`;

// evaluate routes
export const ROUTE_EVALUATE = '/evaluate';
export const ROUTE_EVALUATE_RESULTS = `${ROUTE_EVALUATE}/results`;
export const ROUTE_EVALUATE_RESULTS_ANALYSIS = `${ROUTE_EVALUATE}/results/analysis/:trialId`;
export const ROUTE_EVALUATE_SEGMENT_RESULTS = `${ROUTE_EVALUATE}/results/segment/:trialId`;
export const ROUTE_EVALUATE_RUN = `${ROUTE_EVALUATE}/results/run/analysis`;
export const ROUTE_EVALUATE_SUMMARY = `${ROUTE_EVALUATE}/summary`;
export const ROUTE_EVALUATE_SUMMARY_V2 = `${ROUTE_EVALUATE}/summary-v2`;
export const ROUTE_VIEW_ALL_EVALUATES = `${ROUTE_EVALUATE}/results-all`;

// customize routes
export const ROUTE_CUSTOMIZE = '/customize';
export const ROUTE_CUSTOMIZE_CONFIGURE = `${ROUTE_CUSTOMIZE}/configure`;

// datasets routes
export const ROUTE_DATASETS = '/datasets';
export const ROUTE_DATASETS_MANAGEMENT = `${ROUTE_DATASETS}/management`;
export const ROUTE_DATASETS_CONFIGURE = `${ROUTE_DATASETS}/configure/:datasetId`;

//pipelines
export const ROUTE_CONFIGURE = `/configure`;
export const ROUTE_CONFIGURE_PIPELINES = `${ROUTE_CONFIGURE}/pipelines`;

export const pathToIndex = {
    [ROUTE_INVESTIGATE_TRENDS]: 0,
    [ROUTE_INVESTIGATE_OPPORTUNITIES]: 1,
    [ROUTE_TAKE_ACTION_SAVED]: 2,
    [ROUTE_TAKE_ACTION_CUSTOM]: 3,
    [ROUTE_EVALUATE_RESULTS]: 4,
    [ROUTE_CUSTOMIZE_CONFIGURE]: 5,
    [ROUTE_DATASETS_MANAGEMENT]: 6,
    [ROUTE_DATASETS_CONFIGURE.replace("/:datasetId","")]: 6,
    [ROUTE_EVALUATE_RUN]: 8,
    [ROUTE_EVALUATE_SUMMARY]: 9,
    [ROUTE_TAKE_ACTION_NU_PLAYS]: 10,
    [ROUTE_CONFIGURE_PIPELINES]: 11,
    [ROUTE_TAKE_ACTION_NU_PLAYS_V2]:12
};

export const PathType = {
    PDP: 'PDP',
    NonPDP: 'NonPDP',
    All: 'all'
}

export const RoleMappings = [
    {
        action: 'trends.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'opportunities.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'take_action.custom.view',
        user_type: 'pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor']
    },
    {
        action: 'take_action.plays.view',
        user_type: 'pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'take_action.plays.v2.view',
        user_type: 'pdp',
        allowed_roles: ['super_administrator', 'demo']
    },
    {
        action: 'results.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.analysis.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.analysis.treatment_selector',
        user_type: 'pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.segment.results.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.run.analysis.view',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.run.analysis.create',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor']
    },
    {
        action: 'results.summary.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'results.summary.v2.view',
        user_type: 'pdp',
        allowed_roles: ['super_administrator', 'demo']
    },
    {
        action: 'datasets_management.view',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'datasets_management.upload',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor']
    },
    {
        action: 'datasets_management.configure',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor']
    },
    {
        action: 'datasets_management.delete',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor']
    },
    {
        action: 'datasets_configure.view',
        user_type: 'non_pdp',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'pipelines.view',
        user_type: 'all',
        allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer']
    },
    {
        action: 'run_analysis.random_state.view',
        user_type: 'all',
        allowed_roles: ['super_administrator']
    },
    {
        action: 'results.experiment.delete',
        user_type: 'all',
        allowed_roles: ['super_administrator']
    },
    {
        action: 'pipelines.viewLogs',
        user_type: 'all',
        allowed_roles: ['super_administrator']
    },
]

export const appRoutesGrouped = [
    {
        group: {
            id: 0,
            name: <Trans i18nKey="feature.investigate" />,
            pathType: PathType.All
        },
        items: [
            {
                id: pathToIndex[ROUTE_INVESTIGATE_TRENDS],
                path: ROUTE_INVESTIGATE_TRENDS,
                displayName: <Trans i18nKey="feature.investigate.trends" />,
                icon: <TrendingUpIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.All
            },
            {
                id: pathToIndex[ROUTE_INVESTIGATE_OPPORTUNITIES],
                path: ROUTE_INVESTIGATE_OPPORTUNITIES,
                displayName: <Trans i18nKey="feature.investigate.opportunities" />,
                icon: <SentimentSatisfiedAltIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.All
            },
        ],
    },
    {
        group: {
            id: 1,
            name: <Trans i18nKey="feature.take_action" />,
            pathType: PathType.All
        },
        items: [
            {
                id: pathToIndex[ROUTE_TAKE_ACTION_CUSTOM],
                path: ROUTE_TAKE_ACTION_CUSTOM,
                displayName: <Trans i18nKey="feature.take_action.custom" />,
                icon: <DonutSmallIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor'],
                pathType: PathType.PDP
            },
            {
                id: pathToIndex[ROUTE_TAKE_ACTION_NU_PLAYS_V2],
                path: ROUTE_TAKE_ACTION_NU_PLAYS_V2,
                displayName: <Trans i18nKey="feature.take_action.nu_plays" />,
                icon: <LocalLibraryIcon />,
                allowed_roles: ['super_administrator', 'demo'],
                pathType: PathType.PDP
            },
            {
                id: pathToIndex[ROUTE_TAKE_ACTION_NU_PLAYS],
                path: ROUTE_TAKE_ACTION_NU_PLAYS,
                displayName: <Trans i18nKey="feature.take_action.nu_plays" />,
                icon: <LocalLibraryIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.PDP
            },
        ],
    },
    {
        group: {
            id: 2,
            name: <Trans i18nKey="feature.evaluate" />,
            pathType: PathType.All
        },
        items: [
            {
                id: pathToIndex[ROUTE_EVALUATE_RESULTS],
                path: ROUTE_EVALUATE_RESULTS,
                displayName: <Trans i18nKey="feature.evaluate.results" />,
                icon: <InsertChartIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.All
            },
            {
                id: pathToIndex[ROUTE_EVALUATE_SUMMARY_V2],
                path: ROUTE_EVALUATE_SUMMARY_V2,
                displayName: <Trans i18nKey="feature.evaluate.summary" />,
                icon: <ArticleIcon />,
                allowed_roles: ['super_administrator', 'demo'],
                pathType: PathType.PDP
            },
            {
                id: pathToIndex[ROUTE_VIEW_ALL_EVALUATES],
                path: ROUTE_VIEW_ALL_EVALUATES,
                displayName: <Trans i18nKey="feature.evaluate.compare" />,
                icon: <CompareIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.NonPDP
            },
            {
                id: pathToIndex[ROUTE_EVALUATE_SUMMARY],
                path: ROUTE_EVALUATE_SUMMARY,
                displayName: <Trans i18nKey="feature.evaluate.summary" />,
                icon: <ArticleIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.All
            },
        ],
    },
    {
        group: {
            id: 3,
            name: <Trans i18nKey="feature.datasets"/>,
            pathType: PathType.NonPDP
        },
        items: [
            {
                id: pathToIndex[ROUTE_DATASETS_MANAGEMENT],
                path: ROUTE_DATASETS_MANAGEMENT,
                displayName: <Trans i18nKey="feature.datasets.management"/>,
                icon: <FolderIcon/>,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.NonPDP
            },
        ],
    },
    {
        group: {
            id: 3,
            name: <Trans i18nKey="feature.configure" />,
            pathType: PathType.All
        },
        items: [
            {
                id: pathToIndex[ROUTE_CONFIGURE_PIPELINES],
                path: ROUTE_CONFIGURE_PIPELINES,
                displayName: <Trans i18nKey="feature.configure.pipelines" />,
                icon: <SettingsApplicationsIcon />,
                allowed_roles: ['super_administrator', 'administrator', 'editor', 'viewer'],
                pathType: PathType.All
            }
        ],
    },
];
// hooks/usePipelineLogs.js
import { useQuery } from '@tanstack/react-query';
import api from 'services/api';

const usePipelineLogs = (pipelineId, enabled = false) => {
  const fetchLogs = async () => {
    try {
      const results = await api.get(`api/v1/pipelines/${pipelineId}/logs`);
      const {
        data: {
          payload
        },
      } = await results.json();

      return payload;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(
    ['pipeline-logs', pipelineId],
    fetchLogs,
    {
      enabled: enabled && !!pipelineId,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
};

export default usePipelineLogs;